@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
@import "~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin";

.ag-theme-material {
  @include ag-theme-alpine(
    (
      foreground-color: rgb(26, 25, 25),
      grey-color: rgb(83, 83, 83),
      green-color: green,
      data-color: ag-derived(grey-color),
      range-selection-border-color: lightgray,
      header-height: 80px,
      secondary-foreground-color: ag-derived(foreground-color),
      odd-row-background-color: #fbfbfc,
      secondary-border-color: transparent,
      borders: false,
    )
  );

  .ag-paging-panel {
    height: 25px;
    padding-top: 8px;
    border: 0;
  }

  .ag-header-cell {
    font-size: 15px;
    background-color: white;
    // font-family: ("Helvetica Neue", sans-serif),
  }

  .ag-header-center .ag-header-cell-label {
    justify-content: center;
  }

  .ag-cell-left {
    text-align: left;
  }

  .ag-cell-right {
    text-align: right;
  }

  .ag-cell-center {
    text-align: center;
  }
  // .ag-header-cell-label {
  //     justify-content: center;
  //  }

  /* .grid-cell-centered {
    text-align: center;
    } */

  .ag-row-hover {
    background-color: lightgray !important;
    //color: white !important;
  }

  .ag-link-icon,
  .ag-link-white {
    color: rgb(83, 83, 83);
  }

  .ag-link-icon svg,
  .ag-link-white svg {
    margin: 8px;
  }

  .ag-row-hover .ag-link-icon {
    color: white;
  }

  .ag-icon-green:hover svg {
    fill: #27a700 !important;
  }

  .ag-icon-yellow:hover svg {
    fill: #fbff00 !important;
  }

  .ag-icon-red:hover svg {
    fill: #a7000e !important;
  }

  .ag-icon-white:hover svg {
    fill: white !important;
  }
}

.my-vertical-cron .c-host {
  display: flex;
  flex-direction: row;
  .c-tabs {
    flex-direction: column;
    border: 0;
    border-right: 1px solid #ccc;
    .c-tab {
      text-align: left;
      border: 0;
      border-radius: 0;
    }
  }
  .c-tab-content {
    padding-top: 0.5rem;
    padding-left: 1rem;
  }
}

.card-grid-padding {
  padding: 15px;
}
